import "./printStyles.css";

import { ChevronDown } from "@bookpeep/ui";
import { Button, Menu, MenuItem } from "@mui/material";
import { ConfirmationDialogModal } from "components/common";
import useCheckoutModal from "hooks/useCheckoutModal";
import html2canvas from "html2canvas";
import HandleRefundModal from "modals/AppointmentDrawer/AppointmentDetails/HandleRefundModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "store";
import { setAppointmentStatus } from "store/appointments/appointmentsSlice";
import { getClient } from "store/clients/clientsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { setCalendarCardsStatusByAppointmentId } from "store/slices/calendar/calendarSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import {
  getInvoice,
  updateInvoice,
  voidRefundInvoice,
} from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import { AppointmentStatusOptions } from "types/Appointment";
import { InvoiceStatus, InvoiceType } from "types/Checkout";
import useNotify from "utils/notifications/useNotify";

import { useCheckoutFormContext } from "../CheckoutFormContext";

type PromiseResolve = (value?: any) => void;

const InvoiceMoreOptionsButton = () => {
  const { t } = useTranslation("invoices");
  const [isRefundAmountModalOpen, setIsRefundAmountModalOpen] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();

  const { invoiceId, setInvoiceId, setCheckoutModalView } = useCheckoutModal();
  const invoice = useSelector(selectInvoiceById(invoiceId || 0));

  const hasPeepGiftCardArticle = invoice?.articles.some(
    (article) => article.purchasedItem.peepPurchasable
  );

  const paidPeepPayPayments =
    invoice?.payments.filter((payment) => payment.peepPaymentInvoice) || [];

  const peepPaymentTotal = paidPeepPayPayments.length
    ? paidPeepPayPayments?.reduce((currentTotal, payment) => {
        return currentTotal + Number(payment.amount);
      }, 0)
    : 0;

  const isRefundedInvoice = invoice?.type === InvoiceType.Refund;

  const { invoiceCardRef, setValue, refundInvoiceId } = useCheckoutFormContext();

  const [isPrinting, setIsPrinting] = useState(false);

  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);

  // We store the resolve Promise being used in `onBeforeGetContent` here
  const printPromiseResolveRef = useRef<PromiseResolve | null>(null);

  const { hasVoid, hasRefundInvoices } = useSelector(selectAllPermissions);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && printPromiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      printPromiseResolveRef.current();
    }
  }, [isPrinting]);

  const contentToPrint = useCallback(() => {
    return invoiceCardRef.current;
  }, [invoiceCardRef]);

  const printInvoice = useReactToPrint({
    content: contentToPrint,
    onBeforeGetContent: () => {
      return new Promise((resolve, reject) => {
        printPromiseResolveRef.current = resolve;
        setIsPrinting(true);

        if (!invoiceCardRef.current) {
          reject();
          notify(t("printFailed"), "error");
          return;
        }
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      printPromiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  if (!invoiceId || !invoice) return null;

  const isOriginalInvoice = !!invoice?.refundInvoice;

  const isRefundInvoice = invoice.type === InvoiceType.Refund;

  const { number: invoiceNumber, status } = invoice;

  const isMenuOpen = Boolean(menuAnchor);

  const onCloseRefundModal = () => {
    setIsRefundAmountModalOpen(false);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(event.currentTarget);

  const handleClose = () => setMenuAnchor(null);

  const handleDownload = async () => {
    const invoiceCard = document.getElementById("invoice-details");

    if (!invoiceCard || !invoiceNumber) {
      notify(t("downloadFailed"), "error");
      return;
    }

    // html2canvas is kinda shitty with box shadows, so we need to scale the canvas, not sure if it will work on all devices
    const canvas = await html2canvas(invoiceCard, { scale: 2 });

    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imgData;
    link.download = `Invoice ${invoiceNumber}.png`;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  const handlePrintOptionClick = () => {
    printInvoice();
    handleClose();
  };

  const openConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(true);
  const closeConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(false);

  const handleVoidOptionClick = () => {
    openConfirmVoidDialog();
    handleClose();
  };

  const voidInvoice = () => {
    if (!invoiceId) return;

    if (peepPaymentTotal > 0) {
      closeConfirmVoidDialog();
      setIsRefundAmountModalOpen(true);
    } else {
      dispatch(
        isRefundedInvoice
          ? voidRefundInvoice({ id: invoiceId })
          : updateInvoice({ id: invoiceId, data: { status: InvoiceStatus.Void } })
      )
        .unwrap()
        .then(() => {
          if (!isRefundedInvoice) {
            dispatch(
              setAppointmentStatus({
                id: invoice.appointment,
                status: AppointmentStatusOptions.New,
              })
            );
            dispatch(
              setCalendarCardsStatusByAppointmentId({
                appointmentId: invoice.appointment,
                status: AppointmentStatusOptions.New,
              })
            );
          }

          notify(
            t("entitySuccessfullyUpdated", {
              entity: t("invoices:invoice"),
              ns: "translation",
            }),
            "success"
          );

          //  we need to refetch the refunded invoice, so that the refunded invoice status is updated

          if (isRefundedInvoice && refundInvoiceId) {
            dispatch(getInvoice({ id: refundInvoiceId }));
            // we need to refetch the client so that if the refund was to client credit the client credit is updated
            dispatch(getClient(invoice?.client));
            setInvoiceId(refundInvoiceId);
          }
        })
        .catch((error) => {
          notify(
            `${t("entityFailedToBeUpdated", {
              entity: t("invoices:invoice"),
              ns: "translation",
            })}. ${error?.detail ?? ""}`,
            "error"
          );
        });
      closeConfirmVoidDialog();
    }
  };

  const voidInvoiceWithRefundOption = (refundOption: string) => {
    if (!invoiceId) return;

    dispatch(
      isRefundedInvoice
        ? voidRefundInvoice({ id: invoiceId })
        : updateInvoice({
            id: invoiceId,
            data: { status: InvoiceStatus.Void, option: refundOption },
          })
    )
      .unwrap()
      .then(() => {
        notify(
          t("entitySuccessfullyUpdated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          }),
          "success"
        );

        //  we need to refetch the refunded invoice, so that the refunded invoice status is updated

        if (isRefundedInvoice && refundInvoiceId) {
          dispatch(getInvoice({ id: refundInvoiceId }));
          // we need to refetch the client so that if the refund was to client credit the client credit is updated
          dispatch(getClient(invoice?.client));
          setInvoiceId(refundInvoiceId);
        }
      })
      .catch((error) => {
        notify(
          `${t("entityFailedToBeUpdated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          })}. ${error?.detail ?? ""}`,
          "error"
        );
      });

    setIsRefundAmountModalOpen(false);
  };

  const handleRefundedOptionClick = () => {
    setValue("refundInvoice.originalInvoice", invoiceId);
    setCheckoutModalView(CheckoutModalView.Refund);
    handleClose();
  };

  return (
    <>
      <Button fullWidth variant="outlined" endIcon={<ChevronDown />} onClick={openMenu}>
        {t("moreOptions")}
      </Button>

      <Menu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: 152,
          },
        }}
      >
        <MenuItem key="download" onClick={handleDownload}>
          {t("download")}
        </MenuItem>

        <MenuItem key="print" onClick={handlePrintOptionClick}>
          {t("print")}
        </MenuItem>

        <MenuItem
          key="refund"
          sx={{
            color: "error.main",
          }}
          disabled={
            !hasRefundInvoices.editAccess ||
            status !== InvoiceStatus.Paid ||
            isOriginalInvoice ||
            isRefundInvoice ||
            hasPeepGiftCardArticle
          }
          onClick={handleRefundedOptionClick}
        >
          {t("refund")}
        </MenuItem>

        <MenuItem
          key="void"
          sx={{
            color: "error.main",
          }}
          disabled={
            !hasVoid.editAccess ||
            status === InvoiceStatus.Void ||
            isOriginalInvoice ||
            hasPeepGiftCardArticle
          }
          onClick={handleVoidOptionClick}
        >
          {t("void")}
        </MenuItem>
      </Menu>

      {isConfirmVoidDialogOpen && (
        <ConfirmationDialogModal
          isOpen={isConfirmVoidDialogOpen}
          onClose={closeConfirmVoidDialog}
          title={t("voidInvoice")}
          description={t("voidDescription")}
          onConfirmButtonClick={voidInvoice}
          confirmButtonLabel={t("void")}
        />
      )}

      {isRefundAmountModalOpen && (
        <HandleRefundModal
          isInvoice
          isHandleRefundModalOpen={isRefundAmountModalOpen}
          onClose={onCloseRefundModal}
          amount={peepPaymentTotal}
          updateAppointmentWithRefundHandling={voidInvoiceWithRefundOption}
        />
      )}
    </>
  );
};

export default InvoiceMoreOptionsButton;
