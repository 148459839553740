import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  removeArticles,
  selectArticleWithSlotsById,
  upsertArticles,
} from "store/articles/articlesSlice";
import { removeArticleSlots, upsertArticleSlots } from "store/articleSlots/articleSlotsSlice";
import { getClient, upsertClients } from "store/clients/clientsSlice";
import {
  selectOnlinePaymentsByAppointmentId,
  upsertOnlinePayments,
} from "store/onlinePayments/onlinePaymentsSlice";
import { removeCalendarCards, upsertCalendarCards } from "store/slices/calendar/calendarSlice";
import { selectInvoicesByAppointmentId } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { upsertInvoices } from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import { upsertDeposits } from "store/slices/deposits/depositsSlice";
import { upsertPeepTransactions } from "store/slices/peepTransactions/peepTransactionsSlice";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./appointmentsApi";

export const appointmentsAdapter = createEntityAdapter();

const initialState = appointmentsAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
  appointmentDetails: null,
});

const { selectById, selectAll } = appointmentsAdapter.getSelectors((state) => state.appointments);

export const selectAllAppointments = selectAll;

export const selectAppointmentById = (id) => (state) => selectById(state, id);

export const selectAppointmentLoading = (state) => state.appointments.isLoading;

export const selectAppointmentWithArticleSlotsById = (id) => (state) => {
  const appointment = selectById(state, id);
  if (!appointment) return;

  const articles = appointment.articles
    .map((articleId) => selectArticleWithSlotsById(articleId)(state))
    .filter((article) => !!article);

  const invoices = selectInvoicesByAppointmentId(id)(state);

  if (!articles.length) return;

  return { ...appointment, articles, invoices };
};

export const selectAppointmentWithArticleSlotsAndOnlinePaymentsById = (id) => (state) => {
  const appointment = selectById(state, id);
  if (!appointment) return;

  const articles = appointment.articles
    .map((articleId) => selectArticleWithSlotsById(articleId)(state))
    .filter((article) => !!article);

  const invoices = selectInvoicesByAppointmentId(id)(state);

  const onlinePayments = selectOnlinePaymentsByAppointmentId(id)(state);

  if (!articles.length) return;

  return { ...appointment, articles, invoices, onlinePayments };
};

export const getAppointmentsAndDetailsByDates = createThunkFromApi(
  "appointments/getAppointmentsAndDetailsByDates",
  api.getAppointmentsAndDetailsByDates,
  ({ data: { articles, slots, clients } }, { dispatch }) => {
    dispatch(upsertClients(clients));
    dispatch(upsertArticles(articles));
    dispatch(upsertArticleSlots(slots));
  }
);

export const getAppointmentDetails = createThunkFromApi(
  "appointments/getAppointmentDetails",
  api.getAppointmentDetails,
  (
    {
      data: { articles, slots, invoices, onlinePayments, calendarCards, deposit, peepTransactions },
    },
    { dispatch }
  ) => {
    dispatch(upsertArticles(articles));
    dispatch(upsertArticleSlots(slots));
    dispatch(upsertInvoices(invoices));
    dispatch(upsertOnlinePayments(onlinePayments));
    dispatch(upsertPeepTransactions(peepTransactions));
    dispatch(upsertCalendarCards(calendarCards));
    if (deposit) dispatch(upsertDeposits(deposit)); // deposit possibly undefined
  }
);

export const getAppointment = createThunkFromApi("appointments/getAppointment", api.getAppointment);

// This function updates articles, slots, and clients based on changes happening in this store
function updateArticleSlotAndClientStores(response, { dispatch }) {
  const {
    data: {
      appointment: { client: clientId },
      appointment,
      calendarCards,
    },
  } = response;

  // update the list of store clients or else it will be out of sync if we created an appointment with a new client
  if (clientId) dispatch(getClient(clientId));

  const articles = appointment.articles.map(
    ({ slots, packageVariant, serviceVariant, ...article }) => ({
      ...article,
      slots: slots.map((slot) => slot.id),
      packageVariant: packageVariant?.id || packageVariant,
      serviceVariant: serviceVariant?.id || serviceVariant,
    })
  );

  const slots = [].concat(
    ...appointment.articles.map(({ slots }) =>
      slots.map(({ serviceVariant, staff, ...slot }) => ({
        ...slot,
        serviceVariant: serviceVariant?.id || serviceVariant,
        staff: staff?.id || staff,
      }))
    )
  );

  dispatch(upsertArticles(articles));
  dispatch(upsertArticleSlots(slots));
  if (calendarCards) {
    dispatch(upsertCalendarCards(calendarCards));
  }
}

export const createAppointment = createThunkFromApi(
  "appointments/createAppointment",
  api.createAppointment,
  updateArticleSlotAndClientStores
);

export const updateAppointment = createThunkFromApi(
  "appointments/updateAppointment",
  api.updateAppointment,
  (response, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const id = response?.data?.appointment?.id;

    const oldAppointment = selectAppointmentWithArticleSlotsById(id)(getState());

    const articlesToRemove = oldAppointment.articles.map((article) => article.id);
    const slotsToRemove = [].concat(
      ...oldAppointment.articles.map((article) => article.slots.map((slot) => slot.id))
    );

    dispatch(removeArticleSlots(slotsToRemove));
    dispatch(removeArticles(articlesToRemove));
    dispatch(removeCalendarCards(slotsToRemove));

    updateArticleSlotAndClientStores(response, thunkApi);
  }
);

export const deleteAppointment = createThunkFromApi(
  "appointments/removeAppointment",
  api.removeAppointment
);

export const updateArticleSlotPrice = createThunkFromApi(
  "appointments/updateArticleSlotPrice",
  api.updateArticleSlotPrice,
  ({ data: { articles, calendarCards } }, { dispatch }) => {
    dispatch(upsertArticles(articles));
    dispatch(upsertCalendarCards(calendarCards));
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    updateAppointmentLogs(state, { payload }) {
      appointmentsAdapter.upsertOne(state, {
        ...payload.appointment,
        logs: payload.logs,
        deposit: payload.deposit,
      });
    },

    setAppointmentStatus(state, { payload }) {
      appointmentsAdapter.updateOne(state, {
        id: payload.appointment,
        changes: { status: payload.status },
      });

      if (state.appointmentDetails && state.appointmentDetails.id === payload.appointment) {
        state.appointmentDetails = { ...state.appointmentDetails, status: payload.status };
      }
    },
  },
  extraReducers: {
    [updateArticleSlotPrice.fulfilled]: (state, { payload: { appointment } }) => {
      state.isLoading = false;
      state.isRequestPending = false;
      appointmentsAdapter.upsertOne(state, appointment);
    },
    [updateArticleSlotPrice.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [getAppointmentsAndDetailsByDates.pending]: (state) => {
      state.isLoading = true;
    },
    [getAppointmentsAndDetailsByDates.fulfilled]: (state, { payload: { appointments } }) => {
      state.isLoading = false;
      state.error = "";
      appointmentsAdapter.upsertMany(state, appointments);
    },

    [getAppointmentDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getAppointmentDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      state.appointmentDetails = payload;
      appointmentsAdapter.upsertOne(state, payload.appointment);
    },

    [getAppointment.pending]: (state) => {
      state.isLoading = true;
    },
    [getAppointment.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      appointmentsAdapter.upsertOne(state, payload);
    },
    [getAppointment.rejected]: (state) => {
      state.isLoading = false;
    },

    [createAppointment.pending]: (state) => {
      state.isLoading = true;
      state.isRequestPending = true;
    },
    [createAppointment.fulfilled]: (state, { payload: { appointment } }) => {
      state.isLoading = false;

      const modifiedAppointment = {
        ...appointment,
        articles: appointment.articles.map((article) => article.id),
      };

      appointmentsAdapter.addOne(state, modifiedAppointment);
      state.isRequestPending = false;
    },
    [createAppointment.rejected]: (state) => {
      state.isLoading = false;
      state.isRequestPending = false;
    },
    [updateAppointment.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateAppointment.fulfilled]: (state, { payload: { appointment } }) => {
      const modifiedAppointment = {
        ...appointment,
        articles: appointment.articles.map((article) => article.id),
      };

      appointmentsAdapter.updateOne(state, {
        id: appointment.id,
        changes: modifiedAppointment,
      });
      state.isLoading = false;
      state.isRequestPending = false;
    },
    [updateAppointment.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [deleteAppointment.fulfilled]: (state, { payload: id }) => {
      appointmentsAdapter.removeOne(state, id);
    },
  },
});

export default appointmentsSlice.reducer;

export const { updateAppointmentLogs, setAppointmentStatus } = appointmentsSlice.actions;
